.wrapper {
  background: rgba(124, 124, 124, 0.75);
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.6s;
  z-index: -1;
  &_open {
    z-index: 500;
    opacity: 1;
  }
}
.modal {
  min-width: 700px;
  max-height: 350px;
  overflow-y: auto;
  // width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: calc(50% + 90px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  &_open {
    display: flex;
  }
  &_close {
    transition: all 0.4s;
    border: none;
    background-color: transparent;
    &:hover {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
}
.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
}
