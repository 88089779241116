@import 'bootstrap';
@import url('../fonts/RobotoCondensed.css');
*,
html * /* override x.xhtml.ru style */ {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
  border: 1px solid transparent;
}
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Roboto Condensed', 'Helvetica', sans-serif;
}

div#root {
  display: flex;
  flex-direction: column;
  // height: 100%;
}

main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

.table-bordered {
  border-collapse: separate;
  border-spacing: 0px;

  td, th {
    border-width: 0 0 1px 1px;

    &:last-child {
      border-right-width: 1px;
    }
  }

  thead > tr:first-child > th {
    border-top-width: 1px;
  }
}

.table-multiple-cell-inner {
    height: 100%;
    display: grid;
    grid-auto-rows: 1fr;
}
.table-multiple-cell-inner > *:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}

.pecs-stat-table {
  width: fit-content;

  th {
    vertical-align: top;
  }

  &.pecs-slice-status-stat-table {
    th:nth-child(n+3), td:nth-child(n+3) {
      text-align: center;
    }

    tr.total {
      font-weight: 700;
    }
  }

  &:not(.pecs-slice-status-stat-table) {
    th:not(:first-child), td:not(:first-child) {
      text-align: center;
    }

    tr:last-child {
      font-weight: 700;
    }
  }
}
