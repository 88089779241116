.cardHeader {
  display: flex;
  &StatusWork {
    background-color: #ffec99;
  }
  &Info {
    background-color: #ffd8a8;
  }
  &Equipment {
    background-color: #96f2d7;
  }
  &Audit {
    background-color: #e9ecef;
  }
  &AttachedFiles {
    background-color: #d8f5a2;
  }
  &Comments {
    background-color: #99e9f2;
  }
  &History {
    background-color: #b2f2bb;
  }
  &NetworkSwitch {
    background-color: #d0bfff;
  }
  &IpCamera {
    background-color: #bac8ff;
  }
  &VideoServer {
    background-color: #a5d8ff;
  }
}
.cardBody {
  &StatusWork {
    background-color: #fff9db;
  }
  &Info {
    background-color: #fff4e6;
  }
  &Equipment {
    background-color: #e6fcf5;
  }
  &Audit {
    background-color: #f1f3f5;
  }
  &AttachedFiles {
    background-color: #f4fce3;
  }
  &Comments {
    background-color: #e3fafc;
  }
  &NetworkSwitch {
    background-color: #f3f0ff;
  }
  &IpCamera {
    background-color: #edf2ff;
  }
  &VideoServer {
    background-color: #e7f5ff;
  }
}
.cardHeaderArrow {
  position: relative;
  &:after {
    content: '\f0d7';
    color: #007bff;
    width: 10px;
    margin-left: 5px;
    font-family: 'FontAwesome';
  }
  &[aria-expanded='true']::after {
    content: '\f0d8';
  }
}
.table {
  width: 100%;
  border: 1px solid #dee2e6;
  &TdStatus {
    width: 30%;
  }
  &TdInfo {
    width: 35%;
  }
}
.tHead {
  border-bottom: 2px solid #dee2e6;
  background-color: #f1f3f5;
}

.cctvInfoCollapse {
  max-height: 300px;
  overflow-y: auto;
}
.contentActive {
  max-height: max-content;
}
.auditInput {
  width: 192px;
  height: 38px;
  padding-left: 10px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
